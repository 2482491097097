/* global Raven */

import { Component } from 'react';
import Raven from 'raven-js';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount = () => {
    const _this = this;
    window.onerror = function myErrorHandler(errorMsg) {
      _this.setState({ error: errorMsg });
      Raven.captureException(errorMsg, { extra: errorMsg });
    };
  };

  componentDidCatch(error, errorInfo) {
    window.alert(error);
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
